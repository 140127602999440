import React, { useEffect, useMemo, useRef, useState } from 'react';
import './BerichtBezorgingen.scss';
import BerichtBezorgingenTable from './BerichtbezorgingenTable/BerichtbezorgingenTable';
import { useAppDispatch, useTypedSelector } from 'store';
import { getBerichtBezorgingen } from 'store/selectors/berichtbezorgingen';
import { fetchBerichtbezorgingMetFilter } from 'store/actions/berichtbezorging/data';
import commandBarStyles from 'styles/commandBarStyles';
import { CommandBar, ICommandBarItemProps, ResponsiveMode } from '@fluentui/react';
import { Berichtstatus } from 'enums/berichtStatus';
import StatusTabs from './StatusTabs/StatusTabs';
import { BerichtCount } from './BerichtCount';
import { useHistory, useParams } from 'react-router-dom';
import OhkModal from 'components/OhkModal';
import { EndpointInfoRequest } from 'interfaces/EndpointInfoRequest';
import { EndpointType } from 'enums/endpointType';
import EndpointInformation from 'components/EndpointInformation/EndpointInformation';
import { setDateFilterRange } from 'store/actions/dateFilter/dateFiler';
import { selectDateFilter } from 'store/selectors/dateFilter';
import { IDateRange } from 'components/DateRangeSelector/IDateRange';
import DateRangeSelector from 'components/DateRangeSelector/DateRangeSelector';
import { BerichtBezorgingRequest } from 'interfaces/berichtbezorging';
import { DateFilterRange } from 'interfaces/dateFilterRange';
import { Zoeken } from './Zoeken/Zoeken';
import { BerichtZoekTerm } from './Zoeken/BerichtZoekTerm';
import { ZoekVelden } from './Zoeken/ZoekVelden';


interface RouterParams {
  status: string;
}

const BerichtBezorgingen = () => {
  const dispatch = useAppDispatch();
  const [refetch, setRefetch] = useState(false);
  const [statusFilter, setStatusFilter] = useState(Berichtstatus.Geen);
  const { list: items, status: fetchStatus } = useTypedSelector(getBerichtBezorgingen);
  const history = useHistory();
  const [EndpointInfoRequest, setEndpointInfoRequest] = useState<EndpointInfoRequest>();
  const { status } = useParams<RouterParams>();
  const [zoekTermFilter, setZoekTermFilter] = useState<BerichtZoekTerm>({
    zoekTerm: '',
    veld: ZoekVelden.opdrachtnummer
  });

  const berichtBezorgingenTableRef = useRef<{ resetFilters: () => void }>(null);

  const handleResetFilters = () => {
    if (berichtBezorgingenTableRef.current) {
      berichtBezorgingenTableRef.current.resetFilters();
    }
  };

  const dateFilterRange = useTypedSelector(selectDateFilter);
  const dateRange = {
    dateRangeType: dateFilterRange.dateRangeType,
    start: new Date(dateFilterRange.start),
    end: new Date(dateFilterRange.end)
  } as IDateRange;
  const setDateRange = (range: IDateRange) => {
    var dateFilterRange = {
      dateRangeType: range.dateRangeType,
      start: range.start.toISOString(),
      end: range.end.toISOString()
    } as DateFilterRange;
    dispatch(setDateFilterRange(dateFilterRange));
  };

  function onDateRangeChanged(dateRange: IDateRange) {
    setDateRange({ ...dateRange });
  }

  // Luister naar de status die in de url staat bijv: /berichten/2, voor status 2
  // Zet de statusFilter op deze status
  useEffect(() => {
    const berichtStatus = status ? parseInt(status) : Berichtstatus.Geen;
    setStatusFilter(berichtStatus);
  }, [status]);


  useEffect(() => {
    if (refetch) {
      retrieveData();
      setRefetch(false);
    }
  }, [dispatch, refetch]);

  useEffect(() => {
    retrieveData();
  }, [dispatch, dateFilterRange]);
  const retrieveData = () => {
    const request = {
      datumVan: dateRange.start,
      datumTm: dateRange.end
    } as BerichtBezorgingRequest;
    var fetchPromise = dispatch(fetchBerichtbezorgingMetFilter(request));
    return () => {
      fetchPromise.abort();
    };
  };

  const commandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'pagetitle',
        onRender: () => (
          <span className="page-title">Alle berichten</span>
        )
      }
    ],
    []
  );

  const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'resetFilters',
        text: 'Alle filters wissen',
        iconProps: { iconName: 'Clear', className: 'icon' },
        split: false,
        ariaLabel: 'Alle filters wissen',
        onClick: handleResetFilters,
        className: 'refresh-button'
      },
      {
        key: 'refresh',
        text: 'Vernieuwen',
        iconProps: { iconName: 'Refresh', className: 'icon' },
        split: false,
        ariaLabel: 'Vernieuwen',
        onClick: () => setRefetch(true),
        className: 'refresh-button'
      }
    ],
    [setRefetch]
  );

  function onStatusFilterChanged(filter: string) {
    if (filter === '0') {
      history.push(`/berichten`);
    } else {
      history.push(`/berichten/${filter}`);
    }
  }

  function berichtCount(): BerichtCount {
    return {
      alle: items.length,
      onbestelbaar: items.filter(item => item.statusId === Berichtstatus.Onbestelbaar).length,
      bezorgd: items.filter(item => item.statusId === Berichtstatus.Bezorgd).length,
      inBehandeling: items.filter(item => item.statusId === Berichtstatus.InBehandeling).length,
      nietBezorgd: items.filter(item => item.statusId === Berichtstatus.NietBezorgd).length,
      handmatigAfgehandeld: items.filter(item => item.statusId === Berichtstatus.HandmatigAfgehandeld).length
    } as BerichtCount;
  }

  function onEndpointVanClicked(id: number): any {
    setEndpointInfoRequest({ endpointType: EndpointType.Ontvangst, endpointId: id });
  }

  function onEndpointNaarClicked(id: number): any {
    setEndpointInfoRequest({ endpointType: EndpointType.Bezorging, endpointId: id });
  }

  return (
    <div className="berichtbezorgingen">
      <div className="berichtbezorgingen-header">
        <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles} />
        <div className="berichtbezorgingen-header-actions">
          <div className="berichtbezorgingen-header-actions-top flex-container flex-gap-16">
            <DateRangeSelector dateRange={dateRange} onDateRangeChange={onDateRangeChanged}></DateRangeSelector>
            <Zoeken zoekTerm={zoekTermFilter}
                    onZoekTermChanged={zoekTermChanged => setZoekTermFilter(zoekTermChanged)}></Zoeken>
          </div>
          <div className="berichtbezorgingen-header-actions-bottom">
            <StatusTabs statusFilter={statusFilter} berichtCount={berichtCount()}
                        onStatusFilterChanged={onStatusFilterChanged}></StatusTabs>
          </div>
        </div>
      </div>
      <div className="berichtbezorgingen-content">
        <BerichtBezorgingenTable items={items}
                                 fetchStatus={fetchStatus}
                                 statusFilter={statusFilter}
                                 zoekTermFilter={zoekTermFilter}
                                 onVanClicked={onEndpointVanClicked}
                                 onNaarClicked={onEndpointNaarClicked}
                                 ref={berichtBezorgingenTableRef}
        />

      </div>
      {EndpointInfoRequest &&
        <OhkModal title="Endpoint informatie"
                  isOpen={!!EndpointInfoRequest}
                  onClose={() => setEndpointInfoRequest(undefined)}
                  onDismiss={() => setEndpointInfoRequest(undefined)}
                  responsiveMode={ResponsiveMode.large}>
          <EndpointInformation endpointInfoRequest={EndpointInfoRequest}></EndpointInformation>
        </OhkModal>
      }
    </div>
  );
};

export default BerichtBezorgingen;