import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { IBerichtbezorging } from 'interfaces/berichtbezorging';
import {
  Dropdown,
  FontIcon,
  IColumn,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  Link,
  mergeStyles,
  TooltipHost
} from '@fluentui/react';
import { getPropertyName } from 'lib/interfaceUtils';
import './BerichtBezorgingenTable.scss';
import { stringToLocaleString } from 'lib/internationalization';
import { fetchBlob } from 'store/actions/blobs/data';
import { useAppDispatch } from 'store';
import { Constants } from 'lib/constants/icons';
import OhkTable from 'components/OhkTable';
import { BerichtZoekTerm } from '../Zoeken/BerichtZoekTerm';
import { ZoekVelden } from '../Zoeken/ZoekVelden';

interface IBerichtBezorgingTableProps {
  items: IBerichtbezorging[];
  fetchStatus: string;
  statusFilter: number;
  zoekTermFilter?: BerichtZoekTerm;
  onSelectionChanged?: (ids: string[]) => void;
  onVanClicked?: (id: number) => void;
  onNaarClicked?: (id: number) => void;
}

type filteringColumns = 'opdrachtStatus' | 'werkwijze' | 'soort' | 'status' | 'van' | 'naar'

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    paddingTop: 7
  },
  dropdownItemHeader: {
    border: 0
  },
  title: {
    border: 0,
    ':focus': {
      backgroundColor: '#f3f2f1',
      border: 0
    },
    ':hover': {
      backgroundColor: '#f3f2f1'
    }
  },
  dropdownItemSelected: {
    border: 0,
    ':focus': {
      backgroundColor: '#f3f2f1',
      border: 0
    },
    ':hover': {
      backgroundColor: '#f3f2f1'
    }
  },
  dropdown: {
    border: 0,
    ':focus': {
      backgroundColor: '#f3f2f1',
      border: 0
    },
    ':hover': {
      border: 0,
      backgroundColor: '#f3f2f1'
    }
  },
  dropdownItems: {
    border: '1px solid #f3f2f1',
    boxShadow: '#f3f2f1 0px 0px 9px -1px'
  }
};

const BerichtBezorgingenTable = forwardRef<{ resetFilters: () => void }, IBerichtBezorgingTableProps>((props, ref) => {

    const {
      items,
      fetchStatus,
      statusFilter,
      onSelectionChanged,
      onVanClicked,
      onNaarClicked,
      zoekTermFilter
    } = props;

    const dispatch = useAppDispatch();
    const [opdrachtStatus, setOpdrachtStatus] = useState<string | undefined>();
    const [werkwijze, setWerkwijze] = useState<string | undefined>();
    const [soort, setSoort] = useState<string | undefined>();
    const [status, setStatus] = useState<string | undefined>();
    const [van, setVan] = useState<string | undefined>();
    const [naar, setNaar] = useState<string | undefined>();

    const resetFilters = () => {
      setOpdrachtStatus(undefined);
      setWerkwijze(undefined);
      setSoort(undefined);
      setStatus(undefined);
      setVan(undefined);
      setNaar(undefined);
    };

    useImperativeHandle(ref, () => ({
      resetFilters
    }));

    function handleVanNaarClick(id: number, isVan: boolean) {
      if (isVan) {
        onVanClicked?.(id);
      } else {
        onNaarClicked?.(id);
      }
    }

    const handleBlobDownload = useCallback(
      (blobId: string | undefined, soort: 'ontvangen-bericht' | 'response-ontvangst' | 'bezorgd-bericht' | 'response-bezorging') => {
        if (!blobId) return;

        const fileName = `${soort}-${blobId}.xml`;
        dispatch(fetchBlob({ id: blobId, fileName })).then();
      },
      [dispatch]
    );

    const filteredItems = useMemo(() => {
      return items
        .filter(item => (statusFilter === 0 || item.statusId === statusFilter))
        .filter(item => (!opdrachtStatus || item.opdrachtStatus === opdrachtStatus))
        .filter(item => (!werkwijze || item.werkwijze === werkwijze))
        .filter(item => (!soort || item.soort === soort))
        .filter(item => (!status || item.status === status))
        .filter(item => (!van || item.van === van))
        .filter(item => (!naar || item.naar === naar))
        .filter(item => {
          if (!zoekTermFilter?.zoekTerm?.trim()) return true;
          const term = zoekTermFilter.zoekTerm.trim().toLowerCase();
          return zoekTermFilter.veld === ZoekVelden.opdrachtnummer
            ? item.opdracht?.toLowerCase().includes(term)
            : true;
        });
    }, [items, statusFilter, opdrachtStatus, werkwijze, soort, status, van, naar, zoekTermFilter]);

    const statusIcon = (statusId: number): string => {
      switch (statusId) {
        case 1:
          return Constants.IconBerichtStatusOnbestelbaar;
        case 2:
          return Constants.IconBerichtStatusInBehandeling;
        case 3:
          //StatusCircleCheckmark
          return Constants.IconBerichtStatusBezorgd;
        case 4:
          return Constants.IconBerichtStatusNietBezorgd;
        case 5:
          return Constants.IconBerichtStatusHandmatigAfgehandeld;
        default:
          return Constants.IconBerichtStatusHOnbekend;
      }
    };

    const statusFieldClass = (statusId: number): string => {
      return `status-field status-field-${statusId}`;
    };

    const iconStatusClass = mergeStyles({
      fontSize: 16,
      height: 16,
      width: 16,
      margin: '0 4px'
    });

    const handleFilterChange = useCallback((option?: IDropdownOption, filterKey?: filteringColumns) => {
      if (!filterKey) return;

      const filterSetters = {
        opdrachtStatus: setOpdrachtStatus,
        werkwijze: setWerkwijze,
        soort: setSoort,
        status: setStatus,
        van: setVan,
        naar: setNaar
      };

      filterSetters[filterKey](option && option.key !== 0 ? option.key.toString() : undefined);
    }, []);

    const FilterDropdown = ({ filterKey, placeholder, selectedKey, options }: {
      filterKey: filteringColumns;
      placeholder: string;
      selectedKey: string | undefined;
      options: IDropdownOption[];
    }) => (
      <Dropdown
        placeholder={placeholder}
        selectedKey={selectedKey || ''}
        options={options}
        calloutProps={{ style: { width: 'min-content' }, calloutWidth: 0 }}
        onRenderTitle={(props) => (
          <span>{props?.[0]?.text === selectedKey ? `${placeholder} "${props?.[0]?.text || ''}"` : placeholder}</span>
        )}
        onChange={(event, option) => handleFilterChange(option, filterKey)}
        onClick={(e) => e.stopPropagation()}
        styles={dropdownStyles}
      />
    );


    const getDropdownOptions = (key: keyof IBerichtbezorging): IDropdownOption[] => {
      const uniqueValues = Array.from(new Set(items.map(item => item[key])));

      return [
        { key: 0, text: 'Filter wissen' },
        ...uniqueValues
          .filter((value): value is string | number => value !== null && value !== undefined && !(value instanceof Date))
          .map(value => ({ key: value, text: String(value) }))
      ];
    };

    const opdrachtStatusOptions = useMemo(() => getDropdownOptions('opdrachtStatus'), [items]);
    const werkwijzeOptions = useMemo(() => getDropdownOptions('werkwijze'), [items]);
    const soortOptions = useMemo(() => getDropdownOptions('soort'), [items]);
    const statusOptions = useMemo(() => getDropdownOptions('status'), [items]);
    const vanOptions = useMemo(() => getDropdownOptions('van'), [items]);
    const naarOptions = useMemo(() => getDropdownOptions('naar'), [items]);

    const columns: IColumn[] = useMemo(() =>
        [
          {
            key: 'iconStatus',
            name: 'Status icon',
            className: 'status-field',
            iconClassName: 'header-status-field-icon',
            iconName: 'Page',
            isIconOnly: true,
            minWidth: 24,
            maxWidth: 24,
            onRender: (item: IBerichtbezorging) => (
              <div className={statusFieldClass(item.statusId)}><FontIcon aria-label="status icon"
                                                                         iconName={statusIcon(item.statusId)}
                                                                         className={iconStatusClass} /></div>
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('ontvangen'),
            name: 'Ontvangen',
            fieldName: getPropertyName<IBerichtbezorging>('ontvangen'),
            minWidth: 80,
            maxWidth: 100,
            isResizable: true,
            data: 'date',
            onRender: (item: IBerichtbezorging) => (
              <span>{item.ontvangen && stringToLocaleString(item.ontvangen)}</span>
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('opdracht'),
            name: 'Opdrachtnummer',
            fieldName: getPropertyName<IBerichtbezorging>('opdracht'),
            minWidth: 100,
            maxWidth: 140,
            isResizable: true,
            data: 'string'
          },
          {
            key: getPropertyName<IBerichtbezorging>('opdrachtStatus'),
            name: 'Opdrachtstatus',
            fieldName: getPropertyName<IBerichtbezorging>('opdrachtStatus'),
            minWidth: 160,
            maxWidth: 180,
            isResizable: true,
            data: 'string',
            onRenderHeader: () => (
              <FilterDropdown
                filterKey="opdrachtStatus"
                placeholder="Opdrachtstatus"
                selectedKey={opdrachtStatus}
                options={opdrachtStatusOptions}
              />
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('soort'),
            name: 'Soort',
            fieldName: getPropertyName<IBerichtbezorging>('soort'),
            minWidth: 150,
            maxWidth: 160,
            isResizable: true,
            data: 'string',
            onRenderHeader: () => (
              <FilterDropdown
                filterKey="soort"
                placeholder="Soort"
                selectedKey={soort}
                options={soortOptions}
              />
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('werkwijze'),
            name: 'Werkwijze',
            fieldName: getPropertyName<IBerichtbezorging>('werkwijze'),
            minWidth: 150,
            maxWidth: 160,
            isResizable: true,
            data: 'string',
            onRenderHeader: () => (
              <FilterDropdown
                filterKey="werkwijze"
                placeholder="Werkwijze"
                selectedKey={werkwijze}
                options={werkwijzeOptions}
              />
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('status'),
            name: 'Status',
            fieldName: getPropertyName<IBerichtbezorging>('status'),
            minWidth: 160,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRenderHeader: () => (
              <FilterDropdown
                filterKey="status"
                placeholder="Status"
                selectedKey={status}
                options={statusOptions}
              />
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('laatsteMutatie'),
            name: 'Laatste mutatie',
            fieldName: getPropertyName<IBerichtbezorging>('laatsteMutatie'),
            minWidth: 110,
            maxWidth: 130,
            isResizable: true,
            data: 'date',
            onRender: (item: IBerichtbezorging) => (
              <span>{item.laatsteMutatie && stringToLocaleString(item.laatsteMutatie)}</span>
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('van'),
            name: 'Van',
            fieldName: getPropertyName<IBerichtbezorging>('van'),
            minWidth: 150,
            maxWidth: 160,
            isResizable: true,
            data: 'string',
            onRender: (item: IBerichtbezorging) => {
              const hasVanApp = !!item.vanId;
              return (
                <Link
                  key={item.berichtId}
                  onClick={() => (hasVanApp && !!item.vanId ? handleVanNaarClick(item.vanId, true) : undefined)}
                >{item.van}</Link>
              );
            },
            onRenderHeader: () => (
              <FilterDropdown
                filterKey="van"
                placeholder="Van"
                selectedKey={van}
                options={vanOptions}
              />
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('naar'),
            name: 'Naar',
            fieldName: getPropertyName<IBerichtbezorging>('naar'),
            minWidth: 180,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: IBerichtbezorging) => {
              const hasNaarApp = item.naarId;
              return (
                <>
                  {item.naarId && item.naarId > 0 ?
                    <Link
                      key={item.berichtId}
                      onClick={() => (hasNaarApp && !!item.naarId ? handleVanNaarClick(item.naarId, false) : undefined)}
                    >{item.naar}</Link>
                    :
                    <span>{item.naar}</span>
                  }
                </>
              );
            },
            onRenderHeader: () => (
              <FilterDropdown
                filterKey="naar"
                placeholder="Naar"
                selectedKey={naar}
                options={naarOptions}
              />
            )
          },
          {
            key: getPropertyName<IBerichtbezorging>('toelichting'),
            name: 'Toelichting',
            fieldName: getPropertyName<IBerichtbezorging>('toelichting'),
            minWidth: 80,
            maxWidth: 600,
            isResizable: true,
            data: 'string',
            onRender: (item: IBerichtbezorging) => {
              return (
                <TooltipHost
                  content={item.toelichting ?? ''}
                  id="tooltip-toelichting"
                >
                  <span>{item.toelichting}</span>
                </TooltipHost>
              );
            }
          },
          {
            key: 'origineelbericht',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            isResizable: false,
            onRender: (item: IBerichtbezorging) => (
              <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: 'auto' }}>
                <IconButton
                  styles={{
                    root: { height: 'auto', ':active': { background: '#ffffff' } },
                    splitButtonContainer: { width: 'auto' }
                  }}
                  iconProps={{ iconName: 'MoreVertical' }}
                  menuProps={{
                    items: [
                      {
                        key: 'berichtinfo',
                        text: `Bericht: ${item.berichtId.toString()}-${item.bezorgingId.toString()}`,
                        disabled: true
                      },
                      {
                        key: 'downloadontvangstbericht',
                        text: 'Ontvangen bericht',
                        iconProps: { iconName: 'Download' },
                        disabled: !item.ontvangenBerichtId,
                        onClick: () => handleBlobDownload(item.ontvangenBerichtId, 'ontvangen-bericht')
                      },
                      {
                        key: 'downloadontvangstResponse',
                        text: 'Response ontvangst',
                        iconProps: { iconName: 'Download' },
                        disabled: !item.ontvangenBerichtResponseId,
                        onClick: () => handleBlobDownload(item.ontvangenBerichtResponseId, 'response-ontvangst')
                      },
                      {
                        key: 'downloadbezorgdbericht',
                        text: 'Bezorgd bericht',
                        iconProps: { iconName: 'Download' },
                        disabled: !item.bezorgdBerichtId,
                        onClick: () => handleBlobDownload(item.bezorgdBerichtId, 'bezorgd-bericht')
                      },
                      {
                        key: 'downloadbezordResponse',
                        text: 'Response bezorging',
                        iconProps: { iconName: 'Download' },
                        disabled: !item.bezorgdBerichtResponseId,
                        onClick: () => handleBlobDownload(item.bezorgdBerichtResponseId, 'response-bezorging')
                      },
                      {
                        key: 'httpResponseCode',
                        text: item.bezorgingHttpStatusCode ? `HTTP response status code bezorging: ${item?.bezorgingHttpStatusCode}` : '',
                        disabled: true
                      }
                    ]
                  }}
                  hidden={true}
                  menuIconProps={{ iconName: '' }}
                />
              </div>
            )
          }
        ],
      [handleVanNaarClick]);

    const handleGetKey = (item): string => {
      return (item as IBerichtbezorging).key;
    };

    return (
      <div className="berichtbezorgingen-table">
        <OhkTable
          disableSort={false}
          columns={columns}
          items={filteredItems}
          loading={fetchStatus === 'pending'}
          onGetKey={handleGetKey}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    );
  }
);

export default BerichtBezorgingenTable;