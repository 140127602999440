import React, { useEffect } from 'react';
import Row from './Row';
import Counter from 'components/Counter';
import { useAppDispatch, useTypedSelector } from 'store';
import { getStatistiekenPerStatus } from 'store/selectors/dashboard';
import { SharedColors, Spinner, SpinnerSize } from '@fluentui/react';
import { fetchStatistiekenPerStatus } from 'store/actions/dashboard/statistiekenPerStatus';
import { IDateRange } from 'components/DateRangeSelector/IDateRange';
import { DashboardDateRange } from 'interfaces/dashboard';
import { useHistory } from 'react-router-dom';
import { Berichtstatus } from 'enums/berichtStatus';
import primaryTheme from '../../../themes/primary';

interface IProps {
  dateRange: IDateRange;
}

const CounterPerStatusEnTotaal: React.FC<IProps> = props => {
  const { dateRange } = props;
  const dispatch = useAppDispatch();
  const { data, status } = useTypedSelector(getStatistiekenPerStatus);
  const history = useHistory();

  useEffect(() => {
    var dateRange = { datumVan: props.dateRange.start, datumTm: props.dateRange.end } as DashboardDateRange;
    const fetchPromise = dispatch(fetchStatistiekenPerStatus(dateRange));

    return () => {
      fetchPromise.abort();
    };
  }, [dispatch, dateRange]);

  function onClickTotaal(): any {
    history.push('/berichten');
  }

  return (
    <>
      <Row>
        {status === 'pending' ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <div className="statistiek-alle flex-grow flex-container flex-gap-16">
            <Counter number={data.alleBerichten} text={'Totaal berichten'} onClick={onClickTotaal} />
          </div>
        )}
      </Row>
      <Row title="Berichten per status">
        {status === 'pending' ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <div className="statistiek-perstatus flex-grow flex-container flex-gap-16">
            <Counter
              number={data.onbestelbaar}
              text={'Onbestelbaar'}
              borderBottomColor={data.onbestelbaar > 0 ? SharedColors.red10 : primaryTheme.palette.green}
              onClick={() => history.push(`/berichten/${Berichtstatus.Onbestelbaar}`)}
            />
            <Counter number={data.inBehandeling}
                     text={'In behandeling'}
                     borderBottomColor={data.inBehandeling > 0 ? SharedColors.orange10 : ''}
                     onClick={() => history.push(`/berichten/${Berichtstatus.InBehandeling}`)}
            />
            <Counter number={data.nietBezorgd}
                     text={'Niet bezorgd'}
                     borderBottomColor={data.nietBezorgd > 0 ? SharedColors.red10 : primaryTheme.palette.green}
                     onClick={() => history.push(`/berichten/${Berichtstatus.NietBezorgd}`)}
            />
            <Counter number={data.bezorgd}
                     text={'Bezorgd'}
                     borderBottomColor={data.bezorgd > 0 ? primaryTheme.palette.green : ''}
                     onClick={() => history.push(`/berichten/${Berichtstatus.Bezorgd}`)} />
            <Counter number={data.handmatigAfgehandeld}
                     text={'Handmatig afgehandeld'}
                     borderBottomColor={data.handmatigAfgehandeld > 0 ? SharedColors.orange10 : ''}
                     onClick={() => history.push(`/berichten/${Berichtstatus.HandmatigAfgehandeld}`)}
            />
          </div>
        )}
      </Row>
    </>
  );
};

export default CounterPerStatusEnTotaal;
