import React, { useEffect, useRef, useState } from 'react'
import { Image, Persona, PersonaSize } from '@fluentui/react'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIsInitialized } from 'store/selectors/app'
import ProfileCallout from './ProfileCallout'
import { useIsAuthenticated } from '@azure/msal-react'
import { useAppDispatch, useTypedSelector } from 'store'
import { getProfile } from 'store/selectors/profile'
import { MsalClient } from 'services/MsalClient'
import { getCorporatie } from 'store/selectors/corporatie'
import { fetchCorporatie } from 'store/actions/corporatie/data'
import { ActionButton } from '@fluentui/react/lib/Button'

const Toolbar = () => {
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()

  const [imageUrl, setImageUrl] = useState('')
  const [profileCalloutVisible, setProfileCalloutVisible] = useState(false)

  const isInitialized = useSelector(getIsInitialized)
  const profile = useTypedSelector(getProfile)

  const personaRef = useRef<HTMLDivElement>(null)
  const ready = isAuthenticated && isInitialized

  const { corporatie } = useTypedSelector(getCorporatie)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (ready) {
      const fetchImage = async () => {
        try {
          const token = await MsalClient.getGraphToken()
          const resp = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })

          const url = URL.createObjectURL(await resp.blob())
          setImageUrl(url)
        } catch (error) {
        }
      }

      fetchImage()

      return () => {
        if (imageUrl) {
          URL.revokeObjectURL(imageUrl)
        }
      }
    }
  }, [ready])

  const handleHomePageClick = () => {
    history.push('/')
  }

  useEffect(() => {
    if (ready) {
      dispatch(fetchCorporatie())
    }
  }, [ready])

  return (
    <div className="toolbar">
      <div className="toolbarContainer">
        <div className="toolbarLeft">
          <div className="toolbarItem" onClick={handleHomePageClick}>
            <Image src="/logo.png" height={48} />
          </div>
        </div>
        <div className="toolbarCenter">
          <span className="corporatie-naam" data-test="toolbar-corporatie-naam">{corporatie && corporatie.naam}</span>
          <span className="corporatie-glncode"
                data-test="toolbar-corporatie-glncode">GLN code: {corporatie && corporatie.gln}</span>
        </div>
        <div className="toolbar-help-buttons">
          <ActionButton href="https://klanthub.databalk.app/" target="_blank" title="Naar Klanthub">
            <Image src="/Naar KlantHub.svg" height={30} />
          </ActionButton>
          <ActionButton href="https://klanthub.databalk.app/ " target="_blank" title="Zaak aanmaken">
            <Image src="/Zaak Aanmaken.svg" height={30} />
          </ActionButton>
          <ActionButton href="https://klanthub.databalk.app/kennisbank" className="customActionButton" target="_blank"
                        title="Kennisbank raadplegen">
            <Image src="/Kennisbank raadplegen.svg" height={30} />
          </ActionButton>
        </div>
        <div className="toolbarRight">
          <div className="toolbarItem toolbarIcon" ref={personaRef}>
            <Persona
              size={PersonaSize.size40}
              imageInitials={profile.shortName}
              imageUrl={imageUrl}
              onClick={() => setProfileCalloutVisible(prev => !prev)}
              styles={{ root: { width: 40 } }}
            />
          </div>
          <ProfileCallout
            visible={profileCalloutVisible}
            onDismiss={() => setProfileCalloutVisible(false)}
            targetRef={personaRef}
            profile={profile}
          />
        </div>
      </div>
    </div>
  )
}

export default Toolbar
