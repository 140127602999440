import { createTheme } from '@fluentui/react'


const primaryTheme = createTheme({
  palette: {
    themePrimary: '#174d95',
    themeLighterAlt: '#f4f3fa',
    themeLighter: '#d4d2ec',
    themeLight: '#b2aedb',
    themeTertiary: '#736cb7',
    themeSecondary: '#443c96',
    themeDarkAlt: '#2e277a',
    themeDark: '#272167',
    themeDarker: '#1d184c',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
    green: '#50c878'
  }
})

export default primaryTheme
