import React, { useEffect, useMemo } from 'react';
import Row from './Row';
import { useAppDispatch, useTypedSelector } from 'store';
import { getEndpointsPrestaties } from 'store/selectors/dashboard';
import { IColumn, Link } from '@fluentui/react';
import { IEndpointPrestatie } from 'interfaces/applicatiePrestatie';
import { getPropertyName } from 'lib/interfaceUtils';
import { useHistory } from 'react-router';
import { IDateRange } from 'components/DateRangeSelector/IDateRange';
import { DashboardDateRange } from 'interfaces/dashboard';
import { fetchEndpointsBezorgenPrestatie } from 'store/actions/dashboard/endpoints';
import OhkTable from 'components/OhkTable';

interface IProps {
  dateRange: IDateRange
}

const StatistiekBezorgenEndpoints: React.FC<IProps> = props => {
  const {dateRange} = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {endpointBezorgen: items, status} = useTypedSelector(getEndpointsPrestaties);

  useEffect(() => {
    var dateRange = { datumVan: props.dateRange.start, datumTm: props.dateRange.end} as DashboardDateRange;
    const fetchPromise = dispatch(fetchEndpointsBezorgenPrestatie(dateRange));

    return () => {
      fetchPromise.abort()
    }
  }, [dispatch, dateRange]);

  const handleGetKey = (item): string => {
    return (item as IEndpointPrestatie).endpointId.toString()
  };

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: getPropertyName<IEndpointPrestatie>('endpointVan'),
        fieldName: getPropertyName<IEndpointPrestatie>('endpointVan'),
        name: 'Naar',
        minWidth: 100,
        maxWidth: 300,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSorted: false,
        onRender: (item: IEndpointPrestatie) => (
            <Link onClick={() => {
              !!item?.aannemerId
                  ? history.push(`/aannemers/${item?.aannemerId}`)
                  : history.push(`/corporatie`)
            }}>{item.endpointVan}</Link>
        ),
      },
      {
        key: getPropertyName<IEndpointPrestatie>('endpointNaam'),
        fieldName: getPropertyName<IEndpointPrestatie>('endpointNaam'),
        name: 'Endpoint naam',
        minWidth: 100,
        maxWidth: 300,
        isResizable: true,
        data: 'string'
      },
      {
        key: getPropertyName<IEndpointPrestatie>('aantalFouten'),
        fieldName: getPropertyName<IEndpointPrestatie>('aantalFouten'),
        name: 'Fouten',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        data: 'number',
        isSorted: true,
        isSortedDescending: true,
      },
    ],
    []
  );

  return (
    <Row title="Endpoints met niet bezorgde DICO berichten">
      <div style={tableStyles}>
        <OhkTable
          disableSort={false}
          columns={columns}
          items={items}
          loading={status === 'pending'}
          onGetKey={handleGetKey}
        />
      </div>
    </Row>
  )
};

export default StatistiekBezorgenEndpoints

const tableStyles: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  height: '100%',
  width: '100%',
  maxWidth: '1200px',
  minHeight: '50px',
};
