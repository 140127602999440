import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { invokeFetch } from 'services/apiClient'
import { ICorporatie } from 'interfaces/corporatie'


interface ICorporatieState extends IStateMachine {
  corporatie: ICorporatie
}

const initState: ICorporatieState = {
  corporatie: {
    naam: '',
    gln: ''
  },
  status: 'idle',
  error: ''
}

const fetchCorporatie = createAsyncThunk<ICorporatie, undefined, { state: RootState }>(
  'corporatie/fetch',
  async (_, thunkApi) => {
    return await invokeFetch<ICorporatie>(thunkApi, 'GET', '/instellingen/corporatie')
  }
)

export const slice = createSlice({
  name: 'corporatie/data',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCorporatie.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchCorporatie.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.corporatie = action.payload
    })
    builder.addCase(fetchCorporatie.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  }
})

export { fetchCorporatie }

export default slice.reducer
